import { ThemeProvider } from '@mui/material/styles';

import { Box } from '@mui/material';

import theme from './theme';

import Header from './components/Header'
import Footer from './components/Footer'
import Firmware from './components/Firmware'
import Images from './components/Images'
import Software from './components/Software';

function App() {
  const url = window.location.href;
  console.log(url);
  console.log()
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ m: 10 }}>
        <Header />
        <Firmware />
        <Images />
        {url.includes("testupdate.auradine.gold") &&
        <Software />}
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
